import { Badge } from '@cmsgov/design-system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  Card,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material'
import USAMap from '../core/map'
import './ProviderMap.css'
import Paper from '@mui/material/Paper'

export default function ProviderMap() {
  const [stateProviderCount, setStateProviderCount] = useState([])
  const [currentStateProviderCount, setCurrentStateProviderCount] = useState([])

  useEffect(async () => getProviderCounts(), [])

  const getProviderCounts = () => {
    axios
      .get(`${process.env.REACT_APP_HOST_NAME}/counts/provider`)
      .then(res => {
        const providerCounts = res.data
        setStateProviderCount(providerCounts)
      })
  }

  const getProviderCategoryCounts = async stateCode => {
    await axios
      .get(`${process.env.REACT_APP_HOST_NAME}/counts/provider/` + stateCode)
      .then(res => {
        const providerCounts = res.data
        setCurrentStateProviderCount(providerCounts)
      })
  }

  const getTotalProviderCounts = () => {
    let totalCount = 0
    stateProviderCount.map(stateCount => {
      totalCount = totalCount + stateCount.totals
    })

    return totalCount
  }

  const getColor = regionCode => {
    let color = ''
    switch (regionCode) {
      case '01':
        color = '#33FFFF'
        break
      case '02':
        color = '#33FFAA'
        break
      case '03':
        color = '#9900FF'
        break
      case '04':
        color = '#33FF55'
        break
      case '05':
        color = '#FFFF00'
        break
      case '06':
        color = '#CCFFBB'
        break
      case '07':
        color = '#CC7711'
        break
      case '08':
        color = '#DDDDDD'
        break
      case '09':
        color = '#FF00FF'
        break
      case '10':
        color = '#9977AA'
        break
      default:
        break
    }
    return color
  }

  /* optional customization of filling per state and calling custom callbacks per state */
  const statesCustomConfig = () => {
    let obj = {}
    stateProviderCount.map(stateCount => {
      let stateCd = stateCount.stateCd
      return (obj[stateCd] = {
        fill: getColor(stateCount.rgnCd),
        clickHandler: event => {
          getProviderCategoryCounts(event.target.dataset.name)
        }
      })
    })
    return obj

    /* return {
      NJ: {
        fill: 'navy',
        clickHandler: event =>
          console.log('Custom handler for NJ', event.target.dataset),
        mouseOverHandler: event =>
          console.log('Mouse handler for NJ', event.target.dataset)
      },
      NY: {
        fill: '#CC0000'
      }
    } */
  }

  const getProviderTable = () => {
    return (
      <Paper elevation={24} style={{ marginRight: '20px' }}>
        <TableContainer
          sx={{ maxHeight: 600 }}
          component={Paper}
          elevation={24}
        >
          <Table stickyHeader aria-label="simple table" size="small">
            <TableHead>
              <TableRow key="header">
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: 'lightsteelblue',
                    fontWeight: 'bold'
                  }}
                >
                  Provider Category
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    backgroundColor: 'lightsteelblue',
                    fontWeight: 'bold'
                  }}
                >
                  Totals
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStateProviderCount.map(row => (
                <TableRow
                  key={row.prvdrCtgryCd}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" style={{ fontSize: '12px' }}>
                    {row.prvdrCtgryDesc}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '12px' }}>
                    {row.totals}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      /* 
      <tbody>
        {currentStateProviderCount.map((providerType, i) => (
          <tr key={i}>
            {providerType.prvdrCtgryDesc + ' ' + providerType.totals}
          </tr>
        ))}
      </tbody> */
    )
  }

  return (
    <div className="section-map">
      <h2 className="count-title">Provider Counts - FY 2022 </h2>
      <h3 className="count-subtitle">
        Total Providers across all states: {getTotalProviderCounts()}
      </h3>

      <div className="text-center">
        <ul className="nav nav-tabs data-nav-tabs">
          <li className="active">
            <a data-toggle="tab" href="#map-view">
              Map view
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#list-view">
              List view
            </a>
          </li>
        </ul>
      </div>
      <p className="instruction_text">
        Hover over a state or territory to see the total Providers
      </p>
      <p className="instruction_text mini">
        Click on a state or territory to download the FY 22 Provider List
      </p>
      <div className="download-links">
        <span className="instruction_text mini">
          Download FY 2020 National data
        </span>
        &nbsp;
        <Badge style={{ backgroundColor: 'green' }}>XLSX</Badge>
        <Badge style={{ backgroundColor: 'brown' }}>PDF</Badge>
      </div>
      <div className="text-center">
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <USAMap
              customize={statesCustomConfig()}
              Providers={stateProviderCount}
            />
          </Grid>
          <Grid item xs={3}>
            {currentStateProviderCount.length > 0 && getProviderTable()}
          </Grid>
        </Grid>
        {/* customize={statesCustomConfig()} */}
      </div>
    </div>
  )
}
