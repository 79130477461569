import { Pagination } from '@cmsgov/design-system';
import React from 'react';

function PaginationExample() {
  const onPageChange = () => {

  }

  return (
    <div>
      <h2>Pagination Example</h2>
      <Pagination currentPage={5} totalPages={10} onPageChange={onPageChange}  renderHref={(page) => `#/results/${page}`} />
    </div>
  );
}

export default PaginationExample;
