import { UsaBanner, Alert } from '@cmsgov/design-system'
import React from 'react'
import qcorLogo from './images/hpms_local_qcor_logo.gif'
import Container from '@mui/material/Container'
import { CardContent, CardHeader, Grid, Link } from '@mui/material'
import { Card } from '@mui/material'
import './App.css'
import ProviderMap from './components/ProviderMap/ProviderMap'

function App() {
  return (
    <>
      <div style={{ margin: 'auto' }}>
        <UsaBanner />
      </div>
      <div>
        <Grid container style={{ alignItems: 'center', background: 'white' }}>
          <Grid item xs={1} />
          <Grid item xs={2} style={{ textAlign: 'left' }}>
            <img
              src={qcorLogo}
              style={{
                height: '50px',
                width: '100px',
                textAlign: 'left'
              }}
              alt="qcorlogo"
            />
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            <ul className="navStyle">
              <li>
                <a
                  tabIndex="4"
                  href="https://archive-it.org/collections/2651"
                  rel="noopener"
                >
                  Archive
                </a>
              </li>
              &nbsp;|&nbsp;
              <li>
                <a tabIndex="5" href="/sitemap/index.html">
                  Site Map
                </a>
              </li>
              &nbsp;|&nbsp;
              <li>
                <a tabIndex="6" href="/faq/index.html">
                  FAQs
                </a>
              </li>
              &nbsp;|&nbsp;
              <li>
                <a tabIndex="7" href="/Example">
                  Examples
                </a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </div>
      <div>
        <header className="ds-u-display--block ds-u-fill--primary-darkest">
          <ul className="mainNav">
            <li>
              <a href="/Search">Search</a>
            </li>
            &nbsp;
            <li>
              <a href="/Performance">Organization Performance</a>
            </li>
            &nbsp;
            <li>
              <a href="/Reports">Reports</a>
            </li>
            &nbsp;
            <li>
              <a href="/Facilities">Facilities</a>
            </li>
          </ul>
        </header>
      </div>
      <Container maxWidth="1200px" className="container">
        <div className="topic">
          <span>Quality, Certification and Oversight Reports</span>
        </div>
        &nbsp;
        <div className="wrap">
          <div className="search">
            <input
              type="text"
              className="searchTerm"
              placeholder="What are you looking for?"
            />
            <button type="submit" className="searchButton">
              Search
            </button>
          </div>
        </div>
        <div className="wrap" style={{ marginTop: '0px' }}>
          <a
            style={{ color: 'white', textDecorationLine: 'underline' }}
            href="/Advanced"
          >
            Advanced
          </a>
        </div>
        &nbsp;
      </Container>
      <ProviderMap />
      <div>
        <Card
          style={{
            width: '75%',
            transform: 'translate(20%, 20%)'
          }}
        >
          <CardHeader title="New & Noteworthy" />
          <CardContent>
            <Alert heading="Data Refresh">
              <span style={{ fontSize: 'medium' }}>
                The data in this site was last refreshed on 2/15/2022.
              </span>
            </Alert>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default App
