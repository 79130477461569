import React from 'react'
import PropTypes from 'prop-types'
import data from './path'
import USAState from './usa_state'

class USAMap extends React.Component {
  state = {
    isMouseOver: false,
    currentStateRecord: {}
  }

  clickHandler = stateAbbreviation => {
    this.props.onClick(stateAbbreviation)
  }

  mouseOverHandler = stateAbbreviation => {
    // let record = this.getProviderRecord(stateAbbreviation.target.dataset.name)
    // this.setState({ isMouseOver: true })
    // this.setState({ currentStateRecord: record })
  }

  getProviderTotalsCard = stateCode => {
    const record = stateCode
      ? this.getProviderRecord(stateCode)
      : this.state.currentStateRecord
    return record.stateDesc + '-' + record.totals
  }

  getProviderRecord = stateCode => {
    const record = this.props.Providers.filter(rec => rec.stateCd === stateCode)
    return record.length > 0 ? record[0] : record
  }

  fillStateColor = state => {
    if (
      this.props.customize &&
      this.props.customize[state] &&
      this.props.customize[state].fill
    ) {
      return this.props.customize[state].fill
    }

    return this.props.defaultFill
  }

  stateClickHandler = state => {
    if (
      this.props.customize &&
      this.props.customize[state] &&
      this.props.customize[state].clickHandler
    ) {
      return this.props.customize[state].clickHandler
    }
    return this.clickHandler
  }
  stateMouseOverHandler = state => {
    if (
      this.props.customize &&
      this.props.customize[state] &&
      this.props.customize[state].mouseOverHandler
    ) {
      return this.props.customize[state].mouseOverHandler
    }
    return this.mouseOverHandler
  }

  buildPaths = () => {
    let paths = []
    let dataStates = data()
    for (let stateKey in dataStates) {
      const path = (
        <USAState
          key={stateKey}
          stateName={dataStates[stateKey].name}
          dimensions={dataStates[stateKey]['dimensions']}
          state={stateKey}
          fill={this.fillStateColor(stateKey)}
          onClickState={this.stateClickHandler(stateKey)}
          offset={dataStates[stateKey]['offset']}
          onMouseOverState={this.stateMouseOverHandler(stateKey)}
          tooltip={this.getProviderTotalsCard(stateKey)}
        />
      )
      paths.push(path)
    }
    return paths
  }

  render() {
    return (
      <>
        <svg
          className="us-state-map"
          xmlns="http://www.w3.org/2000/svg"
          width={this.props.width}
          height={this.props.height}
          viewBox="0 0 959 593"
        >
          <title>{this.props.title}</title>
          <g className="outlines">{this.buildPaths()}</g>
        </svg>
        {this.state.isMouseOver && <this.getProviderTotalsCard />}
      </>
    )
  }
}

USAMap.propTypes = {
  onClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  title: PropTypes.string,
  defaultFill: PropTypes.string,
  customize: PropTypes.object,
  Providers: PropTypes.array,
  tooltip: PropTypes.string
}

USAMap.defaultProps = {
  onClick: () => {},
  onMouseOver: () => {},
  width: 959,
  height: 593,
  defaultFill: 'rgb(192, 215, 231)',
  title: 'Blank US states map',
  customize: {},
  tooltip: ''
}

export default USAMap
